import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { getProductCenter } from '@/http/product';
import { ElMessage } from 'element-plus';
import { imgUrl } from "@/utils/urlManage";
import { basicMixin } from "@/mixin/basicMixin";
export default defineComponent({
  mixins: [basicMixin],

  setup() {
    const data = reactive({
      productCenterList: []
    });
    const methods = {
      getProductCenterMethod() {
        getProductCenter().then(res => {
          console.log(res);

          if (res.data.code == 200) {
            data.productCenterList = res.data.data;
          } else {
            ElMessage.error(res.statusText);
          }
        });
      }

    };
    onMounted(() => {
      methods.getProductCenterMethod();
    });
    return { ...toRefs(data),
      imgUrl
    };
  }

});